:root {
--primary-widget: #6852D3;
--bg-ghost-widget: #6852D31a;
--text-primary-widget: #000000;
--text-primary-widget-05: #00000080;
--text-company-widget: #000000;
--text-tagline-widget-05: #00000080;

--black: #000;
--dark-black: #2B2B2B;
--light-black: #434040;
--white: #FFFFFF;
--grey: #53575D;
--dark-grey: #57585C;
--light-grey: #B6B8C1;
--black-grey: #2B2B2B4d;
--dim-grey: #696969;
}