.d-block {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

/*----------------------text-decoration----------------------*/
.line-through {
  text-decoration: line-through;
}

/*----------------------text-align----------------------*/

.text-center {
  text-align: center;
}

/*----------------------flex----------------------*/
.d-flex {
  display: flex;
}
.flex {
  display: flex;
}
.column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-end {
  align-items: flex-end;
  justify-content: flex-end;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-baseline {
  align-items: baseline;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-auto {
  flex: 1 1 auto;
}

.justify-self-end {
  justify-self: end;
}

.flex-around-nowrap {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
}

/*----------------------font-weight----------------------*/

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

/*----------------------font-size----------------------*/

.size-10 {
  font-size: 10px;
}

.size-11 {
  font-size: 11px;
}

.size-12 {
  font-size: 12px;
}

.size-13 {
  font-size: 13px;
}

.size-14 {
  font-size: 14px;
}

.size-16 {
  font-size: 16px;
}

.size-22 {
  font-size: 22px;
}

.size-23 {
  font-size: 23px;
}

.size-24 {
  font-size: 24px;
}

.size-30 {
  font-size: 30px;
}

/*----------------------color----------------------*/

.text-primary {
  color: var(--primary-widget);
}

.black-color {
  color: var(--black);
}

.dark-black-color {
  color: var(--dark-black);
}

.light-black-color {
  color: var(--light-black);
}

.smoky-black-color {
  color: #0f0f0f;
}

.white-color {
  color: var(--white);
}

.grey-color {
  color: var(--grey);
}

.dark-grey-color {
  color: var(--dark-grey);
}

.light-grey-color {
  color: var(--light-grey);
}

.black-grey-color {
  color: var(--black-grey);
}

.dim-grey-color {
  color: var(--dim-grey);
}

.davy-grey-color {
  color: #57585c;
}

.grey-dark-color {
  color: #656c7b;
}

.charleston-green-color {
  color: #2b2b2b;
}

/*----------------------spacing----------------------*/
.m-0 {
  margin: 0;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-18 {
  margin-top: 18px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-17 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-7 {
  margin-left: 7px;
}

.ml-10 {
  margin-left: 10px;
}

.pl-10 {
  padding-left: 10px;
}

/*----------------------text-overflow----------------------*/
.text-2line {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-4line {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none !important;
}
.scrollbar-hidden::-webkit-scrollbar-thumb,
.scrollbar-hidden::-webkit-scrollbar-track {
  background: transparent !important;
}
.scrollbar-hidden::-webkit-scrollbar,
.scrollbar-hidden::-moz-scrollbar {
  display: none !important;
}
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
@media (min-width: 768px) {
  .scrollbar-hidden-desktop::-webkit-scrollbar {
    display: none !important;
  }
  .scrollbar-hidden-desktop::-webkit-scrollbar-thumb,
  .scrollbar-hidden-desktop::-webkit-scrollbar-track {
    background: transparent !important;
  }
  .scrollbar-hidden-desktop::-webkit-scrollbar,
  .scrollbar-hidden-desktop::-moz-scrollbar {
    display: none !important;
  }
  .scrollbar-hidden-desktop {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
}
